<template>
    <div>
        <m1 :mdata="casedata"></m1>
    </div>
</template>

<script>
    import m1 from '@/components/common_components/m1.vue'

    export default {
        components: {
            m1
        },
        data() {
            return {
                casedata: []
            }
        },
        created() {
            this.$post("/industry/categoryall",{category:"活动主办"}).then(resp=>{
                this.casedata = resp.dataList
            })
        }
    }
</script>

<style>
</style>
